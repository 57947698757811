import React from 'react';
import { observer } from 'mobx-react-lite';

import Back from '@components/Back';
import ScrollUp from '@components/ScrollUp';
import SearchResults from '@components/SearchResults';
import { useRootStore } from '@stores/index';

import { styled } from '@linaria/react';
import {
  WrapperStyles,
  RightColumnStyles,
  fontMontserrat,
  fontColorDefault
} from '@rambler-help/shared';

const SearchFullPageStyled = styled.div`
  h2 {
    margin: 30px 0;
    ${fontMontserrat}
    ${fontColorDefault}
    font-size: 38px;
    line-height: 45px;
    font-weight: 800;
  }
`;
const SearchPageStyled = styled.div`
  ${WrapperStyles}
  h2 {
    margin: 30px 0;
    ${fontMontserrat}
    ${fontColorDefault}
    font-size: 38px;
    line-height: 45px;
    font-weight: 800;
  }
`;
const Content = styled.div`
  ${RightColumnStyles}
`;

const Search = observer(() => {
  const { projects } = useRootStore();
  return projects.project ? (
    <SearchPageStyled>
      <Content>
        <Back to={'/'}>Все сервисы Рамблера</Back>
        <h2>Помощь в работе с сервисами Рамблера</h2>
        <SearchResults />
        <ScrollUp />
      </Content>
    </SearchPageStyled>
  ) : (
    <SearchFullPageStyled>
      <Back to={'/'}>Все сервисы Рамблера</Back>
      <h2>Помощь в работе с сервисами Рамблера</h2>
      <SearchResults />
      <ScrollUp />
    </SearchFullPageStyled>
  );
});

export default Search;
