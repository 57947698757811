import React, { ReactElement } from 'react';
import Pagination from '@rambler-components/pagination';

import { PageBlock } from '@rambler-help/components';
import { useRootStore } from '@stores/index';
// import { ISearchResult } from '@stores/content/SearchStore';
import { RouteNames } from '@rambler-help/shared';

import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import {
  fontMontserrat,
  fontColorDefault,
  styleLink
} from '@rambler-help/shared';
import {
  DefaultProjectIconStyles,
  // CoolProjectIconStyles,
  // FinanceProjectIconStyles,
  // HoroscopeProjectIconStyles,
  // KassaProjectIconStyles,
  // LikesProjectIconStyles,
  // LoyltyProjectIconStyles,
  // MailProjectIconStyles,
  // NewsProjectIconStyles,
  // ProfileProjectIconStyles,
  // SearchProjectIconStyles,
  // SoftProjectIconStyles,
  // StartProjectIconStyles,
  // Top100ProjectIconStyles,
  // UserProjectIconStyles,
  // WeatherProjectIconStyles,
  // WeekendProjectIconStyles
} from '@rambler-help/shared';
import '@rambler-components/pagination/styles.css';

const layoutPadding = {
  padding: '0',
  '@media screen and (max-width: 1260px)': {
    padding: '0 0 0 40px',
  }
}

const SearchResults = styled.div`
  ${fontMontserrat}
`;
const Results = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #8d96b2;
  margin: 0 0 20px;
`;
const SearchItem = styled.div`
  ${layoutPadding}
  margin: 0 0 25px;
  position: relative;
`;
const ProjectIcon = styled.div`
  position: absolute;
  left: 0;
  top: 5px;
  display: none;
  visibility: hidden;

  @media screen and (max-width: 1260px) {
    display: block;
    visibility: visible;
  }
`;
const Title = styled.div`
  margin: 0 0 5px;
  font-size: 18px;
  line-height: 25px;
  font-weight: 500;
  ${styleLink}
`;
const Url = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 5px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #8d96b2;
  ${styleLink}
   a {
    color: #8d96b2;
  }
`;
const Separator = styled.div`
  display: block;
  content: '';
  width: 20px;
  height: 20px;
  background-image: url('./images/icon-arrow.svg');
`;
const Description = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  & > b {
    font-weight: 600;
    color: #315efb;
  }
`;
const Question = styled.div`
  ${layoutPadding}
  margin: 30px 0 0;
  ${styleLink}
`;
const NoResults = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;
  &::before {
    display: block;
    content: '';
    background-image: url('./images/icon-noresults.svg');
    height: 115px;
    width: 115px;
  }
  & > h2 {
    margin: 30px 0 20px;
    font-size: 26px;
    line-height: 30px;
    font-weight: 800;
  }
  & > p {
    text-align: center;
    margin: 0 0 20px;
  }
  ${styleLink}
`;
const NoResultsTitle = styled.div`
  margin: 30px 0 20px;
  ${fontMontserrat}
  ${fontColorDefault}
  font-size: 26px;
  line-height: 30px;
  font-weight: 800;
`;
const paginator = css`
  justify-content: center;
`;

const SearchResultsComponent = (): ReactElement => {
  const { search, projects } = useRootStore();
  const searchResults = search.results ? JSON.parse(decodeURIComponent(search.results)) : undefined;
  const feedbackLink = projects.project
    ? RouteNames.USER_FEEDBACK_FORM_PAGE.replace(':projectSlug', projects.project.slug)
    : RouteNames.USER_FEEDBACK_COMMON_FORM_PAGE;
  return (
    <SearchResults>
      {searchResults ? (
        <>
          <Results>Найдено {search.resultsTotal} статей</Results>
          {searchResults.map((item: ISearchResult) => {
            const cleanBody = item.body.replace(/(<([^>]+)>)/gi, '');
            return (
              <SearchItem key={item.id}>
                <ProjectIcon style={DefaultProjectIconStyles}/>
                <Title><a href={item.url}>{item.title}</a></Title>
                {item.projectTitle && (
                  <Url>
                    <a href="/">{item.projectTitle}</a>
                    <Separator />
                    <a href="/">{item.sectionTitle}</a>
                  </Url>
                )}
                <Description>
                  {cleanBody.substring(0, 180)}{cleanBody.length > 180 ? '…' : ''}
                </Description>
              </SearchItem>
            );
          })}
          <PageBlock>
            <Question>
              Не нашли ответа на свой вопрос? <a href={feedbackLink}>Напишите в поддержку</a>.
            </Question>
          </PageBlock>
          {!!search.pagesCount && (
            <Pagination
              className={paginator}
              pagesCount={search.pagesCount}
              currentPage={search.currentPage || 1}
              onChange={page => {
                const url = new URL(window.location.href);
                url.searchParams.set('page', page.toString());
                window.location.href = url.toString();
              }}
            />
          )}
        </>
      ) : (
        <NoResults>
          <NoResultsTitle>Ничего не найдено</NoResultsTitle>
          <p>
            Попробуйте изменить запрос, <a href={feedbackLink}>написать в поддержку</a> или<br/>
            возпользуйтесь <a href="https://r0.ru">Рамблер/поиском</a> по всему интернету
          </p>
        </NoResults>
      )}
    </SearchResults>
  );
};

export default SearchResultsComponent;
